/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardItemDto
 */
export interface DashboardItemDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    dashboard: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    widget: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    row: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    column: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    columnSpan: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardItemDto
     */
    rowSpan: number;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardItemDto
     */
    background: boolean;
}

export function DashboardItemDtoFromJSON(json: any): DashboardItemDto {
    return DashboardItemDtoFromJSONTyped(json, false);
}

export function DashboardItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dashboard': json['dashboard'],
        'widget': json['widget'],
        'row': json['row'],
        'column': json['column'],
        'columnSpan': json['columnSpan'],
        'rowSpan': json['rowSpan'],
        'background': json['background'],
    };
}

export function DashboardItemDtoToJSON(value?: DashboardItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dashboard': value.dashboard,
        'widget': value.widget,
        'row': value.row,
        'column': value.column,
        'columnSpan': value.columnSpan,
        'rowSpan': value.rowSpan,
        'background': value.background,
    };
}


