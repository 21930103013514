<template>
  <b-navbar toggleable="lg" :type="$root.themeName" :variant="$root.themeName">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-navbar-brand href="/"><img :src="$root.darkMode ? '/img/logo-dark.svg' : '/img/logo.svg'" alt="" style="height: 2.2rem;" /></b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="this.$root.claimShowDashboard">
        <b-nav-item @click="$router.push('/')">{{$t('DASHBOARDS')}}</b-nav-item>
        <b-nav-item @click="$router.push('/widgets')" v-if="this.$root.claimEditDashboard">{{$t('WIDGETS')}}</b-nav-item>
        <b-nav-item @click="$router.push('/datamodels')" v-if="this.$root.claimEditDataModel">{{$t('DATA_MODELS')}}</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto align-items-center">
        <v-btn class="mr-5 cs-fg-color" icon id="logout" @click="setFullscreen()" v-if="currentRouteName == 'Dashboard'"><cs-icon>SwitchView</cs-icon></v-btn>
        <ThemeSwitch class="ml-5 mr-5" />
        <v-btn class="ml-5 cs-fg-color" icon id="logout" @click="logout"><cs-icon>Logout</cs-icon></v-btn>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  import Vue from 'vue';
  import { AuthService } from '@consolidate/shared/util-auth';
  import ThemeSwitch from './ThemeSwitch.vue';

  export default Vue.extend({
  name: "Navbar",
  components: {
    ThemeSwitch
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
  logout() {
    AuthService.logout();
    },
    setFullscreen() {
      let elm = document.getElementById("dashboard-main-container");
      if(elm != null) {
        if(elm.requestFullscreen) {
          elm.requestFullscreen();
        } else if(elm.webkitRequestFullscreen) {
          elm.webkitRequestFullscreen();
        } else if(elm.msRequestFullscreen) {
          elm.msRequestFullscreen();
        }
      }
    }
},
});
</script>
