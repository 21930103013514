import { ComponentsPlugin } from '@consolidate/shared/ui-components';
import { AuthService } from '@consolidate/shared/util-auth';
import { i18n } from '@consolidate/shared/util-translations';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import App from './App.vue';
import Client from './api/Client';
import router from './router';

import './style/app.scss';

Vue.config.productionTip = false;

Vue.component('gmap-map', VueGoogleMaps.map);
Vue.component('gmap-marker', VueGoogleMaps.marker);
Vue.component('gmap-cluster', GmapCluster);

Vue.use(ComponentsPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

(async () => {
  await AuthService.init('dashboardnew', [
    'cons.dashboardnew',
    'openid',
    'offline_access',
    'profile',
    'consid.dashboard',
  ]);

  let claimShowDashboard = false;
  let claimEditDashboard = false;
  let claimEditDataModel = false;

  const user = await AuthService.getUser();
  if (user != null) {
    claimShowDashboard = Boolean(user['show_dashboard']);
    claimEditDashboard = Boolean(user['edit_dashboard']);
    claimEditDataModel = Boolean(user['edit_datasource_dashboard']);
  }

  if (claimShowDashboard) {
    try {
      const { googleMapsApiKey } = await new Client().settings.settingsGet();

      Vue.use(VueGoogleMaps, {
        load: {
          key: googleMapsApiKey,
          libraries: 'places',
        },
      });
    } catch {
      // ignore unauthenticated api error and dont initialize google maps if we cant get the api key
    }
  }

  new Vue({
    router,
    i18n,
    data: {
      themeName: 'dark',
      darkMode: true,
      claimShowDashboard: claimShowDashboard,
      claimEditDashboard: claimEditDashboard,
      claimEditDataModel: claimEditDataModel,
    },
    render: (h) => h(App),
  }).$mount('#app');
})();
