/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WidgetDataDto,
    WidgetDataDtoFromJSON,
    WidgetDataDtoToJSON,
    WidgetDto,
    WidgetDtoFromJSON,
    WidgetDtoToJSON,
    WidgetTypeDto,
    WidgetTypeDtoFromJSON,
    WidgetTypeDtoToJSON,
} from '../models';

export interface WidgetAddRequest {
    widgetDto: WidgetDto;
}

export interface WidgetDeleteRequest {
    id: number;
}

export interface WidgetGetRequest {
    id: number;
}

export interface WidgetGetDataRequest {
    id: number;
}

export interface WidgetUpdateRequest {
    id: number;
    widgetDto: WidgetDto;
}

/**
 * 
 */
export class WidgetApi extends runtime.BaseAPI {

    /**
     */
    async widgetAddRaw(requestParameters: WidgetAddRequest): Promise<runtime.ApiResponse<WidgetDto>> {
        if (requestParameters.widgetDto === null || requestParameters.widgetDto === undefined) {
            throw new runtime.RequiredError('widgetDto','Required parameter requestParameters.widgetDto was null or undefined when calling widgetAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetDtoToJSON(requestParameters.widgetDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async widgetAdd(widgetDto: WidgetDto): Promise<WidgetDto> {
        const response = await this.widgetAddRaw({ widgetDto: widgetDto });
        return await response.value();
    }

    /**
     */
    async widgetDeleteRaw(requestParameters: WidgetDeleteRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async widgetDelete(id: number): Promise<Blob> {
        const response = await this.widgetDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async widgetGetRaw(requestParameters: WidgetGetRequest): Promise<runtime.ApiResponse<WidgetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async widgetGet(id: number): Promise<WidgetDto> {
        const response = await this.widgetGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async widgetGetAllRaw(): Promise<runtime.ApiResponse<Array<WidgetDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetDtoFromJSON));
    }

    /**
     */
    async widgetGetAll(): Promise<Array<WidgetDto>> {
        const response = await this.widgetGetAllRaw();
        return await response.value();
    }

    /**
     */
    async widgetGetDataRaw(requestParameters: WidgetGetDataRequest): Promise<runtime.ApiResponse<WidgetDataDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetGetData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget/{id}/data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDataDtoFromJSON(jsonValue));
    }

    /**
     */
    async widgetGetData(id: number): Promise<WidgetDataDto> {
        const response = await this.widgetGetDataRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async widgetGetWidgetTypesRaw(): Promise<runtime.ApiResponse<Array<WidgetTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget/widget-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetTypeDtoFromJSON));
    }

    /**
     */
    async widgetGetWidgetTypes(): Promise<Array<WidgetTypeDto>> {
        const response = await this.widgetGetWidgetTypesRaw();
        return await response.value();
    }

    /**
     */
    async widgetUpdateRaw(requestParameters: WidgetUpdateRequest): Promise<runtime.ApiResponse<WidgetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling widgetUpdate.');
        }

        if (requestParameters.widgetDto === null || requestParameters.widgetDto === undefined) {
            throw new runtime.RequiredError('widgetDto','Required parameter requestParameters.widgetDto was null or undefined when calling widgetUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/widget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetDtoToJSON(requestParameters.widgetDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async widgetUpdate(id: number, widgetDto: WidgetDto): Promise<WidgetDto> {
        const response = await this.widgetUpdateRaw({ id: id, widgetDto: widgetDto });
        return await response.value();
    }

}
