








import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';
  import { Line as LineChart } from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
  )

  var labels: string[] = [];
  var data: number[] = [];
  var backgroundColor: string[] = [];
  var borderColor: string[] = [];

export default Vue.extend({
  name: 'LineChartWidget',
  props : {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  components: {
    LineChart
  },
  data(){
    return {
      timer: 0,
      loaded: false,
      chartData: {
        labels: labels,
        datasets: [
          {
            label: ' ',
            data: data,
            pointBackgroundColor: backgroundColor,
            pointBorderColor: borderColor,
            backgroundColor: '#ffffff',
            borderColor: '#ffffff',
            fill: 'false'
          }
        ]
      },
      chartOptions: {
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        x: {
            ticks: {
              color: 'white'
            }
          },
          y: {
            ticks: {
              color: 'white'
            }
          }
      },

      plugins: {
        legend: {
          labels: {
            color: 'white'
        }
      }
    }
      }
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async loadData() {
      this.loaded = false;

      await this.refreshData();

      let config = JSON.parse(this.dto.config ?? "{}");

      if (config.RefreshRate > 0) {
        this.timer = window.setInterval(this.refreshData, config.RefreshRate * 1000);
      }

      this.loaded = true;
    },
    async refreshData() {
      let client = new Client();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      let labels: string[] = [];
      let data: number[] = [];

      for (let row of widgetData.data.data) {
        labels.push(row.label);
        data.push(row.value);
      }

      let config = JSON.parse(this.dto.config ?? "{}");
      this.chartOptions.plugins.legend.labels.color = config.TextColor ?? "white";

      let title = "";
      if(config.ShowTitle == true)
        title = Buffer.from(this.dto.name, "base64").toString();

      this.chartData.labels = labels;
      this.chartData.datasets = [{
        label: title,
        backgroundColor: config.LineColor,
        borderColor: config.LineColor,
        pointBackgroundColor: config.LineColor,
        pointBorderColor: config.LineColor,
        data: data,
        fill: config.AreaChart == true ? 'origin' : 'false'
      }];

      this.chartOptions.scales.x.ticks.color = config.TextColor ?? "white";
      this.chartOptions.scales.y.ticks.color = config.TextColor ?? "white";
    }
  }
});
