



















  import Vue from 'vue';

  export default Vue.extend({
    name: "LineChartEditWidget",
    props: {
      config: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        localConfig: this.config
      }
    }
});
