/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataModelDto
 */
export interface DataModelDto {
    /**
     * 
     * @type {number}
     * @memberof DataModelDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DataModelDto
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataModelDto
     */
    users?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DataModelDto
     */
    query: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataModelDto
     */
    data: Array<string>;
}

export function DataModelDtoFromJSON(json: any): DataModelDto {
    return DataModelDtoFromJSONTyped(json, false);
}

export function DataModelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataModelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'users': !exists(json, 'users') ? undefined : json['users'],
        'query': json['query'],
        'data': json['data'],
    };
}

export function DataModelDtoToJSON(value?: DataModelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'users': value.users,
        'query': value.query,
        'data': value.data,
    };
}


