












































  import Vue from 'vue';
  import Client from '../api/Client';
  import { DataModelDto } from '../api/gen';
  import { i18n } from '@consolidate/shared/util-translations';

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      name: "",
      users: "",
      query: "",
      id: 0,
      loaded: false,
      datafields: ""
    }
  },
  async created() {
    let id: number = parseInt(this.$route.params.id);

    if (id > 0) {
      let client = new Client();
      let dto = await client.dataModel.dataModelGet(id);
      this.name = Buffer.from(dto.name, "base64").toString();
      this.query = Buffer.from(dto.query, "base64").toString();
      this.users = dto.users?.join(",") ?? "";
      this.datafields = dto.data?.join(",") ?? "";
      this.id = dto.id;
    }
    else {
      this.id = 0;
      this.name = i18n.t("NEW_DATAMODEL");
      this.query = "";
      this.users = "";
      this.datafields = "";
    }

    this.loaded = true;
  },
  methods: {
    async save() {
      let client = new Client();

      let strUsers: string[] = this.users.split(",").map(x => x.trim()).filter(function (el) { return el != null && el.length > 0});
      let strData: string[] = this.datafields.split(",").map(x => x.trim()).filter(function (el) { return el != null && el.length > 0});

      let reqDto: DataModelDto = {
        id: this.id,
        name: Buffer.from(this.name).toString("base64"),
        query: Buffer.from(this.query).toString("base64"),
        users: strUsers,
        data: strData
      };

      try {
        if (reqDto.id > 0) {
          await client.dataModel.dataModelUpdate(reqDto.id, reqDto);
        }
        else {
          await client.dataModel.dataModelAdd(reqDto);
        }

        // this.$router.push('/datamodels');
        this.$bvModal
          .msgBoxOk(i18n.t('SAVE_SUCCESS'), {
            title: i18n.t('INFO'),
            okVariant: 'success',
            okTitle: i18n.t('OK'),
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            //
          })
          .catch((err) => {
            // An error occurred
          });
      }
      catch (e) {
        alert(e);
      }
    }
  }
});
