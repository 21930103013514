import { authGuard, loginRoutes } from '@consolidate/shared/feature-login';
import Vue from 'vue';
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router';
import animationData from '../assets/19-building.json';
import Home from '../views/Home.vue';
import Widgets from '../views/Widgets.vue';
import DataModels from '../views/DataModels.vue';
import EditDashboard from '../views/EditDashboard.vue';
import Dashboard from '../views/Dashboard.vue';
import EditDataModel from '../views/EditDataModel.vue';
import EditWidget from '../views/EditWidget.vue';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete

      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

const routes: Array<RouteConfig> = [
  ...loginRoutes('Dashboard', animationData, '5.2000'),
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/widgets',
    name: 'Widgets',
    component: Widgets,
  },
  {
    path: '/datamodels',
    name: 'DataModels',
    component: DataModels,
  },
  {
    path: '/dashboard-edit/:id',
    name: 'EditDashboard',
    component: EditDashboard,
  },
  {
    path: '/dashboard/:id',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/datamodel/:id',
    name: 'EditDataModel',
    component: EditDataModel,
  },
  {
    path: '/widget/:id',
    name: 'EditWidget',
    component: EditWidget,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authGuard);

export default router;
