


















  import Vue from 'vue';
  import Client from '../api/Client';
  import { i18n } from '@consolidate/shared/util-translations';

  var items: { ID: number, Name: string, Users: string }[] = [];

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      fields: [
        {
          key: 'ID',
          label: i18n.t('ID'),
          sortable: true
        },
        {
          key: 'Name',
          label: i18n.t('DASHBOARD_NAME'),
          sortable: true
        },
        {
          key: 'Users',
          label: i18n.t('USERS'),
          sortable: true
        },
        {
          key: 'actions',
          label: ''
        }
      ],
      items: items,
      loaded: false,
      filterText: null
    }
  },
  async created() {
    this.loaded = false;

    await this.loadData();

    this.loaded = true;
  },

  methods: {
    async loadData() {
      let client = new Client();
      let datamodels = await client.dataModel.dataModelGetAll();
      this.items = []

      for (let datamodel of datamodels) {
        let users: string;
        if (datamodel.users) {
          users = datamodel.users.join(", ");
        } else {
          users = "";
        }
        this.items.push({
          ID: datamodel.id,
          Name: Buffer.from(datamodel.name, "base64").toString(),
          Users: users
        });
      }
    },

    showDeleteModal(id: number) {
      this.$bvModal.msgBoxConfirm(i18n.t("DELETE_DATAMODEL_MSG"), {
        title: i18n.t("DELETE_DATAMODEL"),
        okVariant: 'danger',
        okTitle: i18n.t("DELETE"),
        cancelTitle: i18n.t("CANCEL"),
        hideHeaderClose: false,
        centered: true
      })
      .then(async value => {
        await this.deleteItem(id);
      })
      .catch(err => {
        // an error occured
      })
    },
    async deleteItem(id: number) {
      this.loaded = false;
      let client = new Client();

      try {
        await client.dataModel.dataModelDelete(id);
        await this.loadData();
      }
      catch (e) {
        alert(e);
      }

      this.loaded = true;
    },
  }
});
