








import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';
import { Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  var labels: string[] = [];
  var data: number[] = [];
  var backgroundColor: string[] = [];

export default Vue.extend({
  name: 'PieChartWidget',
  props : {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  components: {
    Pie
  },
  data(){
    return {
      timer: 0,
      loaded: false,
      chartData: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            labels: {
              color: 'white'
            }
          },
          title: {
            display: true,
            text: '',
            color: '#ffffff',
            font: {
              size: 18
            }
          }
        }
      }
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async loadData() {
      this.loaded = false;

      let config = JSON.parse(this.dto.config ?? "{}");
      this.chartOptions.plugins.legend.labels.color = config.TextColor ?? "white";
      this.chartOptions.plugins.title.text = Buffer.from(this.dto.name, "base64").toString();
      this.chartOptions.plugins.title.color = config.TextColor ?? "white";
      this.chartOptions.plugins.title.display = config.ShowTitle ?? false;

      await this.refreshData();

      if (config.RefreshRate > 0) {
        this.timer = window.setInterval(this.refreshData, config.RefreshRate * 1000);
      }

      this.loaded = true;
    },
    async refreshData() {
      let client = new Client();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      let labels: string[] = [];
      let backgroundColor: string[] = [];
      let data: number[] = [];

      for (let row of widgetData.data.data) {
        labels.push(row.label);
        backgroundColor.push(row.color);
        data.push(row.value);
      }

      this.chartData.labels = labels;
      this.chartData.datasets = [{
        backgroundColor: backgroundColor,
        data: data
      }];
    }
  }
});
