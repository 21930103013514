



































import Vue from 'vue';
import Client from '../api/Client';
import { DashboardDto, DashboardItemDto } from '../api/gen';
import DashboardItem from '../components/DashboardItem.vue';
import VueGridLayout, { GridItemData } from 'vue-grid-layout';

var items: GridItemData[] = [];
var backgroundItem: DashboardItemDto;

export default Vue.extend({
  name: 'Home',
  components: {
    DashboardItem,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
      id: 0,
      loaded: false,
      items: items,
      index: 0,
      colNum: 12,
      backgroundItem: backgroundItem
    }
  },
  async created() {
    this.loaded = false;
    this.items = [];

    let id: number = parseInt(this.$route.params.id);
    let client = new Client();

    let dto: DashboardDto = await client.dashboard.dashboardGet(id);
    this.id = dto.id;

    let ditems = await client.dashboardItem.dashboardItemGetAll(id);
    for (let item of ditems) {
      if (item.background) {
        this.backgroundItem = item;
        continue;
      }

      let itemData: GridItemData = {
        i: this.index.toString(),
        x: item.column,
        y: item.row,
        w: item.columnSpan,
        h: item.rowSpan,
        minW: 1,
        minH: 1,
        dto: item
      };
      this.items.push(itemData);
      this.index++;
    }

    this.loaded = true;
  },
});
