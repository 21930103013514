/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardItemDto,
    DashboardItemDtoFromJSON,
    DashboardItemDtoToJSON,
} from '../models';

export interface DashboardItemAddRequest {
    dashboardItemDto: DashboardItemDto;
}

export interface DashboardItemDeleteRequest {
    id: number;
}

export interface DashboardItemGetRequest {
    id: number;
}

export interface DashboardItemGetAllRequest {
    dashboardId: number;
}

export interface DashboardItemUpdateRequest {
    id: number;
    dashboardItemDto: DashboardItemDto;
}

/**
 * 
 */
export class DashboardItemApi extends runtime.BaseAPI {

    /**
     */
    async dashboardItemAddRaw(requestParameters: DashboardItemAddRequest): Promise<runtime.ApiResponse<DashboardItemDto>> {
        if (requestParameters.dashboardItemDto === null || requestParameters.dashboardItemDto === undefined) {
            throw new runtime.RequiredError('dashboardItemDto','Required parameter requestParameters.dashboardItemDto was null or undefined when calling dashboardItemAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardItemDtoToJSON(requestParameters.dashboardItemDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardItemAdd(dashboardItemDto: DashboardItemDto): Promise<DashboardItemDto> {
        const response = await this.dashboardItemAddRaw({ dashboardItemDto: dashboardItemDto });
        return await response.value();
    }

    /**
     */
    async dashboardItemDeleteRaw(requestParameters: DashboardItemDeleteRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardItemDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/item/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async dashboardItemDelete(id: number): Promise<Blob> {
        const response = await this.dashboardItemDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dashboardItemGetRaw(requestParameters: DashboardItemGetRequest): Promise<runtime.ApiResponse<DashboardItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardItemGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/item/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardItemGet(id: number): Promise<DashboardItemDto> {
        const response = await this.dashboardItemGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dashboardItemGetAllRaw(requestParameters: DashboardItemGetAllRequest): Promise<runtime.ApiResponse<Array<DashboardItemDto>>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling dashboardItemGetAll.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/item/dashboard/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardItemDtoFromJSON));
    }

    /**
     */
    async dashboardItemGetAll(dashboardId: number): Promise<Array<DashboardItemDto>> {
        const response = await this.dashboardItemGetAllRaw({ dashboardId: dashboardId });
        return await response.value();
    }

    /**
     */
    async dashboardItemUpdateRaw(requestParameters: DashboardItemUpdateRequest): Promise<runtime.ApiResponse<DashboardItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardItemUpdate.');
        }

        if (requestParameters.dashboardItemDto === null || requestParameters.dashboardItemDto === undefined) {
            throw new runtime.RequiredError('dashboardItemDto','Required parameter requestParameters.dashboardItemDto was null or undefined when calling dashboardItemUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/item/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardItemDtoToJSON(requestParameters.dashboardItemDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardItemUpdate(id: number, dashboardItemDto: DashboardItemDto): Promise<DashboardItemDto> {
        const response = await this.dashboardItemUpdateRaw({ id: id, dashboardItemDto: dashboardItemDto });
        return await response.value();
    }

}
