/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetTypes,
    WidgetTypesFromJSON,
    WidgetTypesFromJSONTyped,
    WidgetTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetDataDto
 */
export interface WidgetDataDto {
    /**
     * 
     * @type {number}
     * @memberof WidgetDataDto
     */
    id: number;
    /**
     * 
     * @type {WidgetTypes}
     * @memberof WidgetDataDto
     */
    type: WidgetTypes;
    /**
     * 
     * @type {string}
     * @memberof WidgetDataDto
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof WidgetDataDto
     */
    data: any | null;
}

export function WidgetDataDtoFromJSON(json: any): WidgetDataDto {
    return WidgetDataDtoFromJSONTyped(json, false);
}

export function WidgetDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': WidgetTypesFromJSON(json['type']),
        'name': json['name'],
        'data': json['data'],
    };
}

export function WidgetDataDtoToJSON(value?: WidgetDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': WidgetTypesToJSON(value.type),
        'name': value.name,
        'data': value.data,
    };
}


