import { AuthService } from '@consolidate/shared/util-auth';
import {
  Configuration,
  ConfigurationParameters,
  DashboardApi,
  DataModelApi,
  SettingsApi,
  WidgetApi,
  DashboardItemApi,
} from './gen';

export class ApiConnectionError extends Error {
  constructor() {
    super('api connection failed');
    this.name = 'ApiConnectionError';
  }
}

class Client {
  private configuration: ConfigurationParameters;

  public dashboard: DashboardApi;
  public dataModel: DataModelApi;
  public settings: SettingsApi;
  public widget: WidgetApi;
  public dashboardItem: DashboardItemApi;

  constructor() {
    const baseURL = AuthService.getWebServiceUrl();
    if (!baseURL) {
      throw new Error('Base url not set');
    }

    this.configuration = {
      basePath: baseURL,
      accessToken: () => AuthService.getAccessToken() ?? '',
      fetchApi: async (input, init) => {
        try {
          return await fetch(input, init);
        } catch (error) {
          throw new ApiConnectionError();
        }
      },
      middleware: [
        {
          post: async (context) => {
            if (context.response.status === 401) {
              // TODO: if (LicensingService.checkLicense()) {
              if (!(context.init as any).__isRetryRequest) {
                await AuthService.refreshToken();

                context.init.headers = {
                  ...context.init.headers,
                  Authorization: AuthService.getAccessToken() ?? '',
                };
                (context.init as any).__isRetryRequest = true;

                return await context.fetch(context.url, context.init);
              } else {
                await AuthService.logout();
              }
              // }
            }
          },
        },
      ],
    };

    this.dashboard = new DashboardApi(new Configuration(this.configuration));
    this.dataModel = new DataModelApi(new Configuration(this.configuration));
    this.dashboardItem = new DashboardItemApi(new Configuration(this.configuration));

    // we need to call the api before the auth service is properly initialized, so remove the retrying middleware.
    // we dont want to log out if the request fails, we just dont add the google maps vue component if we dont get the key
    this.settings = new SettingsApi(
      new Configuration({ ...this.configuration, middleware: undefined })
    );
    this.widget = new WidgetApi(new Configuration(this.configuration));
  }
}

export default Client;
