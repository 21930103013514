

















  import Vue, { PropType } from 'vue';
  import { DashboardItemDto, WidgetDto } from '../api/gen';
  import { i18n } from '@consolidate/shared/util-translations';

  var availableWidgets: {value: number, text: string}[] = [];

  export default Vue.extend({
    name: "DashboardEditItem",
    data() {
      return {
        selectedWidget: 0,
        availableWidgets: availableWidgets,
        selectedType: "",
        widgetBackground: "#646464"
      }
    },
    props: {
      id: {
        type: String,
        required: true
      },
      dto: {
        type: Object as PropType<DashboardItemDto>,
        required: true
      },
      widgets: {
        type: Array as PropType<WidgetDto[]>,
        required: true
      },
      saveCallback: {
        type: Function as PropType<(id: string, widgetId: number) => void>,
        required: true
      },
      deleteCallback: {
        type: Function as PropType<(id: string) => void>,
        required: true
      },
    },
    created() {
      this.availableWidgets = [];
      for (let widget of this.widgets) {
        this.availableWidgets.push({value: widget.id, text: Buffer.from(widget.name, "base64").toString()});
      }

      this.availableWidgets = this.availableWidgets.sort((a,b) => {
			let fa = a.text.toLowerCase(), fb = b.text.toLowerCase();
			if (fa < fb) {
				return -1
			}
			if (fa > fb) {
				return 1
			}
			return 0
		})

      this.selectedWidget = this.dto.widget;
      this.setSelectedWidget();
    },
  methods: {
    save() {
      this.setSelectedWidget();
      this.saveCallback(this.id, this.selectedWidget);
    },
    deleteItem() {
      this.deleteCallback(this.id);
    },
    setSelectedWidget() {
      let typeId = this.widgets.find(v => v.id == this.selectedWidget)?.type ?? 0;
      let config = JSON.parse(this.widgets.find(v => v.id == this.selectedWidget)?.config ?? "{}");

      if (typeId == 10)
        this.selectedType = i18n.t("MAP");
      else if (typeId == 7)
        this.selectedType = i18n.t("TABLE");
      else if (typeId == 9)
        this.selectedType = i18n.t("VALUE");
      else if (typeId == 12)
        this.selectedType = i18n.t("CHART_PIE");
      else if (typeId == 11)
        this.selectedType = i18n.t("CHART_LINE");
      else if (typeId == 5)
        this.selectedType = i18n.t("IMAGE");
      else if (typeId == 1)
        this.selectedType = i18n.t("CLOCK_ANALOGUE");
      else if (typeId == 2)
        this.selectedType = i18n.t("CLOCK_DIGITAL");
      else
        this.selectedType = "";

      this.widgetBackground = config.BackgroundColor ?? "#646464";
    }
},
});
