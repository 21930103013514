











































import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';
import { getColoredIconUrl } from './icon-url-generator.js';

interface Marker {
  lat: number;
  lng: number;
  infoText: string;
  Address: string;
  Phone: string;
  Email: string;
  iconColor: string;
}

var widgetMapData: WidgetDataDto;

export default Vue.extend({
  name: 'MapWidget',
  data() {
    return {
      markers: [] as Marker[],
      title: '',
      openedMarkerID: null as number | null,
      center: { lat: 47.4211171, lng: 9.671283 },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      dataLoaded: false,
      widgetMapData: widgetMapData,
      timer: 0,
      dataTimer: 0,
      zoom: 8,
      fitBounds: true,
    };
  },
  props: {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google(): any {
      return gmapApi();
    },
  },
  mounted() {
    this.timer = window.setInterval(() => {
      if (!this.dataLoaded) return;

      const tempBounds = new this.google.maps.LatLngBounds();

      this.title = this.widgetMapData.name;

      this.markers = [];

      this.widgetMapData.data.markers.forEach(
        (marker: {
          location: number[];
          name: string;
          address: string;
          color: string;
          phone: string;
          mail: string;
        }) => {
          const thisMarker: Marker = {
            lat: marker.location[0],
            lng: marker.location[1],
            infoText: marker.name,
            Address: marker.address,
            Phone: marker.phone,
            Email: marker.mail,
            iconColor: getColoredIconUrl(marker.color, marker.color),
            // iconColor:
            //   'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' +
            //   marker.color.replace('#', ''),
          };

          tempBounds.extend(
            new this.google.maps.LatLng(thisMarker.lat, thisMarker.lng)
          );

          this.markers.push(thisMarker);
        }
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let cZoom = this.zoom;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myMapRef as any)?.$mapObject.setZoom(cZoom - 2);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myMapRef as any)?.$mapObject.setZoom(cZoom + 2);

      if (this.fitBounds)
        (this.$refs.myMapRef as any)?.$mapObject.fitBounds(tempBounds);

      this.dataLoaded = false;
    }, 500);
  },
  destroyed() {
    window.clearInterval(this.timer);
    window.clearInterval(this.dataTimer);
  },
  methods: {
    async loadData() {
      let config = JSON.parse(this.dto.config ?? '{}');

      if (config.DefaulLocation != null && config.DefaultLocation.length == 2) {
        this.center = {
          lat: config.DefaultLocation[0],
          lng: config.DefaultLocation[1],
        };
      } else {
        this.center = { lat: 47.4211171, lng: 9.671283 };
      }

      this.zoom = config.ZoomLevel ?? 8;
      this.fitBounds = config.FitBounds ?? true;

      await this.refreshData();

      if (config.RefreshRate > 0) {
        this.dataTimer = window.setInterval(
          this.refreshData,
          config.RefreshRate * 1000
        );
      }
    },
    async refreshData() {
      let client = new Client();

      try {
        this.widgetMapData = await client.widget.widgetGetData(this.dto.id);
        this.dataLoaded = true;
      } catch (e) {}
    },
    openMarker(id: number | null): void {
      this.openedMarkerID = id;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleClusterInfo(cluster: any): void {
      const gmapMarkers = cluster.getMarkers();
      const tooCloseMarkers = gmapMarkers.filter(
        (
          val: {
            getPosition: () => {
              lat: { (): number };
              lng: { (): number };
            };
          },
          index: number,
          arr: {
            getPosition: () => {
              lat: { (): number };
              lng: { (): number };
            };
          }[]
        ) =>
          val.getPosition().lat().toFixed(4) ==
            arr[0].getPosition().lat().toFixed(4) &&
          val.getPosition().lng().toFixed(4) ==
            arr[0].getPosition().lng().toFixed(4)
      );

      if (tooCloseMarkers) {
        //Don't modify first element
        for (let i = 1; i < tooCloseMarkers.length; i++) {
          let existingMarker = tooCloseMarkers[i];

          let pos = existingMarker.getPosition();
          let quot = 360.0 / tooCloseMarkers.length;
          let newLat = pos.lat() + -0.00002 * Math.cos(+quot * i);
          let newLng = pos.lng() + -0.00002 * Math.sin(+quot * i);
          existingMarker.setPosition(
            new this.google.maps.LatLng(newLat, newLng)
          );
        }
      }

      this.center = gmapMarkers[0].getPosition();
      (this.$refs.myMapRef as any)?.$mapObject.setCenter(this.center);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let cZoom = (this.$refs.myMapRef as any)?.$mapObject.getZoom();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myMapRef as any)?.$mapObject.setZoom(cZoom - 2);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.myMapRef as any)?.$mapObject.setZoom(cZoom + 2);
    },
  },
});
