


























  import Vue, { PropType } from 'vue';
  import { DashboardItemDto, WidgetDto } from '../api/gen';
  import Client from '../api/Client';
  import MapWidget from './widgets/MapWidget.vue';
  import ValueWidget from './widgets/ValueWidget.vue';
  import TableWidget from './widgets/TableWidget.vue';
  import ImageWidget from './widgets/ImageWidget.vue';
  import PieChartWidget from './widgets/PieChartWidget.vue';
  import LineChartWidget from './widgets/LineChartWidget.vue';
  import AnalogueClockWidget from './widgets/AnalogueClockWidget.vue';
  import DigitalClockWidget from './widgets/DigitalClockWidget.vue';

  var widget: WidgetDto;

  export default Vue.extend({
    name: "DashboardItem",
    components: {
      MapWidget,
      ValueWidget,
      TableWidget,
      ImageWidget,
      PieChartWidget,
      LineChartWidget,
      AnalogueClockWidget,
      DigitalClockWidget
    },
    data() {
      return {
        widget: widget,
        loaded: false,
        hasError: false,
        loadedWidget: false
      }
    },
    props: {
      dto: {
        type: Object as PropType<DashboardItemDto>,
        required: true
      },
    },
    async mounted() {
      this.loaded = false;
      this.hasError = false;

      let client = new Client();

      try {
        this.widget = await client.widget.widgetGet(this.dto.widget);
        let config = JSON.parse(this.widget.config ?? "{}");

        (this.$refs['widgetContainer'] as HTMLElement).style.backgroundColor = config.BackgroundColor ?? "transparent";
      }
      catch (e) {
        this.hasError = true;
      }

      this.loaded = true;

      this.loadedWidget = false;

      if(this.hasError)
        return;

      await this.$nextTick();

      try {
        await (this.$refs['widgetRef'] as any).loadData();
      }
      catch (e) {
        this.hasError = true;
      }

      this.loadedWidget = true;
    },
});
