














import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';

export default Vue.extend({
  name: 'ValueWidget',
  data() {
    return {
      timer: 0,
      name: "",
      value: "",
      fontSize: "1rem",
      color: "white",
      title: "",
      showTitle: false
    };
  },
  props : {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    async loadData() {

      let config = JSON.parse(this.dto.config ?? "{}");
      this.fontSize = config.FontSize ?? "1rem";
      this.color = config.TextColor ?? "white";
      this.title = Buffer.from(this.dto.name, "base64").toString();
      this.showTitle = config.ShowTitle ?? false;

      await this.refreshData();

      if (config.RefreshRate > 0) {
        this.timer = window.setInterval(this.refreshData, config.RefreshRate * 1000);
      }
    },
    async refreshData() {
      let client = new Client();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      this.name = widgetData.data.label;
      this.value = widgetData.data.value;
    }
  }
});
