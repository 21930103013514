
























































import Vue from 'vue';
import Client from '../api/Client';
import { WidgetDto, DashboardDto, DashboardItemDto } from '../api/gen';
import { i18n } from '@consolidate/shared/util-translations';
import DashboardEditItem from '../components/DashboardEditItem.vue';
import VueGridLayout, { GridItemData } from 'vue-grid-layout';

var name: string;
var users: string;
var id: number;
var availableWidgets: WidgetDto[] = [];
var backgroundWidgets: { value: number, text: string }[] = [];

var items: GridItemData[] = [];
var itemsToDelete: number[] = [];

export default Vue.extend({
  name: 'Home',
  components: {
    DashboardEditItem,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
      name: name,
      users: users,
      id: id,
      loaded: false,
      items: items,
      index: 0,
      colNum: 12,
      availableWidgets: availableWidgets,
      selectedBackgroundWidget: 0,
      oldBackgroundWidget: 0,
      backgroundWidgets: backgroundWidgets,
      itemsToDelete: itemsToDelete
    }
  },
  async created() {
    this.loaded = false;
    let id: number = parseInt(this.$route.params.id);
    let client = new Client();

    this.availableWidgets = await client.widget.widgetGetAll();

    this.backgroundWidgets = [];
    this.itemsToDelete = [];

    for (let widget of this.availableWidgets) {
      this.backgroundWidgets.push({ value: widget.id, text: Buffer.from(widget.name, "base64").toString() });
    }
    this.backgroundWidgets.push({ value: 0, text: i18n.t('BACKGROUND_EMPTY') });

    this.backgroundWidgets = this.backgroundWidgets.sort((a,b) => {
      let fa = a.text.toLowerCase(), fb = b.text.toLowerCase();
      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })

    if (id > 0) {
      let dto: DashboardDto = await client.dashboard.dashboardGet(id);
      this.name = Buffer.from(dto.name, "base64").toString();
      this.users = dto.users?.join(",") ?? "";
      this.id = dto.id;

      let ditems = await client.dashboardItem.dashboardItemGetAll(id);
      for (let item of ditems) {
        if (item.background) {
          this.oldBackgroundWidget = item.id;
          this.selectedBackgroundWidget = item.widget;
        }
        else {
          let itemData: GridItemData = {
            i: this.index.toString(),
            x: item.column,
            y: item.row,
            w: item.columnSpan,
            h: item.rowSpan,
            minW: 1,
            minH: 1,
            dto: item
          };
          this.items.push(itemData);
          this.index++;
        }
      }
    }
    else {
      this.id = 0;
      this.name = i18n.t("NEW_DASHBOARD");
      this.users = "";
    }

    this.loaded = true;
  },
  methods: {
    async save() {
      let client = new Client();

      let strUsers: string[] = this.users.split(",").map(x => x.trim()).filter(function (el) { return el != null && el.length > 0 });

      let reqDto: DashboardDto = {
        id: this.id,
        name: Buffer.from(this.name).toString("base64"),
        users: strUsers
      };

      try {
        if (reqDto.id > 0) {
          await client.dashboard.dashboardUpdate(reqDto.id, reqDto);
        }
        else {
          reqDto = await client.dashboard.dashboardAdd(reqDto);
        }

        for (let item of this.items) {
          item.dto.dashboard = reqDto.id;

          item.dto.row = item.y;
          item.dto.column = item.x;
          item.dto.rowSpan = item.h;
          item.dto.columnSpan = item.w;

          if (item.dto.id > 0) {
            await client.dashboardItem.dashboardItemUpdate(item.dto.id, item.dto);
          }
          else {
            await client.dashboardItem.dashboardItemAdd(item.dto);
          }
        }

        for (let item of this.itemsToDelete) {
          await client.dashboardItem.dashboardItemDelete(item);
        }

        if (this.oldBackgroundWidget > 0)
          await client.dashboardItem.dashboardItemDelete(this.oldBackgroundWidget);

        if (this.selectedBackgroundWidget > 0) {
          let backgroundItem: DashboardItemDto = {
            id: 0,
            dashboard: reqDto.id,
            columnSpan: 12,
            rowSpan: 12,
            widget: this.selectedBackgroundWidget,
            background: true,
            column: 0,
            row: 0,
          };

          await client.dashboardItem.dashboardItemAdd(backgroundItem);
        }

        // this.$router.push('/');
        this.$bvModal
          .msgBoxOk(i18n.t('SAVE_SUCCESS'), {
            title: i18n.t('INFO'),
            okVariant: 'success',
            okTitle: i18n.t('OK'),
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            //
          })
          .catch((err) => {
            // An error occurred
          });
        }
        catch (e) {
          alert(e);
        }
    },
    createItem() {

      let nextRow = 0;
      for(let item of this.items) {
        if(item.y > nextRow)
          nextRow = item.y + 1;
      }

      let item: DashboardItemDto = {
        id: 0,
        dashboard: this.id,
        columnSpan: 1,
        rowSpan: 1,
        widget: 0,
        background: false,
        column: 0,
        row: nextRow, // puts it at the bottom
      };

      let itemData: GridItemData = {
        i: this.index.toString(),
        x: item.column,
        y: item.row,
        w: item.columnSpan,
        h: item.rowSpan,
        minW: 1,
        minH: 1,
        dto: item
      };

      this.items.push(itemData);
      this.index++;
    },
    saveItem(id: string, widgetId: number) {
      let index = this.items.findIndex((x) => x.i === id);
      if (index >= 0) {
        this.items[index].dto.widget = widgetId;
      }
    },
    deleteItem(id: string) {
      let index = this.items.findIndex((x) => x.i === id);;
      if (index >= 0) {
        if (this.items[index].dto.id > 0)
          this.itemsToDelete.push(this.items[index].dto.id);

        this.items.splice(index, 1);
      }
    },
  }
});
