







import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';

export default Vue.extend({
  name: 'DigitalClockWidget',
  props : {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  data() {
    return {
      timezone: "",
      timer: 0,
      timestring: "00:00:00",
      title: "",
      showTitle: false,
      textColor: "white",
      fontSize: "1rem"
    };
  },
  methods: {
    async loadData() {
      let client = new Client();

      this.title = Buffer.from(this.dto.name, "base64").toString();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      if(widgetData.data.timezone != null && widgetData.data.timezone.length > 0)
        this.timezone = widgetData.data.timezone;
      else
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.show();
    },
    show() {
        this.showTime();
      if (this.timer) window.clearInterval(this.timer);
            this.timer = window.setInterval(() => {
                this.showTime();
            }, 100);
    },
    showTime() {
        const options: Intl.DateTimeFormatOptions = {
          timeZone: this.timezone,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        };

        const formatter = new Intl.DateTimeFormat([], options);
        this.timestring = formatter.format(new Date());
    }
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
  mounted() {
    const parentContainer = this.$refs.clockContainer as HTMLElement;

    const containerWidth = parentContainer.clientWidth;
    const containerHeight = parentContainer.clientHeight;

    const fontSize = Math.min(containerWidth / 6, containerHeight / 4);
    this.fontSize = fontSize / 2 + 'px';
    (this.$refs.textContainer as HTMLElement).style.fontSize = fontSize + 'px';

    try {
      let config = JSON.parse(this.dto.config ?? "{}");
      this.showTitle = config?.ShowTitle ?? false;

      (this.$refs.textContainer as HTMLElement).style.color = config.TextColor ?? "white";
      this.textColor = config.TextColor ?? "white";
    } catch {}
  }
});
