











































import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';

var items: Array<object> = [];
var fields: Array<object> = [];

export default Vue.extend({
  name: 'TableWidget',
  data() {
    return {
      timer: 0,
      fields: fields,
      items: items,
      variant: 'dark',
      showFilter: false,
      filterText: null,
      title: '',
      titleColor: 'white',
      showTitle: false,
      showColumnHeader: true,
    };
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  props: {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  methods: {
    async loadData() {
      let config = JSON.parse(this.dto.config ?? '{}');

      this.variant = config.Dark ? 'dark' : 'light';
      this.showFilter = config.EnableSearch ?? false;
      this.title = Buffer.from(this.dto.name, 'base64').toString();
      this.titleColor = this.variant == 'dark' ? 'white' : 'black';
      this.showTitle = config.ShowTitle ?? false;
      this.showColumnHeader = config.showColumnHeader ?? true;

      await this.refreshData();

      if (config.RefreshRate > 0) {
        this.timer = window.setInterval(
          this.refreshData,
          config.RefreshRate * 1000
        );
      }
    },
    async refreshData() {
      let client = new Client();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      this.items = [];
      for (let row of widgetData.data.rows) {
        let item: any = {};
        for (let i = 0; i < row.length; i++) {
          item[widgetData.data.headers[i]] = row[i];
        }
        this.items.push(item);
      }

      let DisplayHeaderStyle: string;
      DisplayHeaderStyle = '';
      if (this.showColumnHeader == false) {
        DisplayHeaderStyle = 'none';
      }

      this.fields = [];
      for (let i = 0; i < widgetData.data.headers.length; i++) {
        this.fields.push({
          sortable: true,
          key: widgetData.data.headers[i],
          label: widgetData.data.headers[i],
          thStyle: {
            display: DisplayHeaderStyle,
          },
        });
      }
    },
  },
});
