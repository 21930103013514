import { render, staticRenderFns } from "./DigitalClockWidget.vue?vue&type=template&id=34328dc8&scoped=true&"
import script from "./DigitalClockWidget.vue?vue&type=script&lang=ts&"
export * from "./DigitalClockWidget.vue?vue&type=script&lang=ts&"
import style0 from "./DigitalClockWidget.vue?vue&type=style&index=0&id=34328dc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34328dc8",
  null
  
)

export default component.exports