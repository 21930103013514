/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DataModelDto,
    DataModelDtoFromJSON,
    DataModelDtoToJSON,
} from '../models';

export interface DataModelAddRequest {
    dataModelDto: DataModelDto;
}

export interface DataModelDeleteRequest {
    id: number;
}

export interface DataModelGetRequest {
    id: number;
}

export interface DataModelUpdateRequest {
    id: number;
    dataModelDto: DataModelDto;
}

/**
 * 
 */
export class DataModelApi extends runtime.BaseAPI {

    /**
     */
    async dataModelAddRaw(requestParameters: DataModelAddRequest): Promise<runtime.ApiResponse<DataModelDto>> {
        if (requestParameters.dataModelDto === null || requestParameters.dataModelDto === undefined) {
            throw new runtime.RequiredError('dataModelDto','Required parameter requestParameters.dataModelDto was null or undefined when calling dataModelAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/datamodel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataModelDtoToJSON(requestParameters.dataModelDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataModelDtoFromJSON(jsonValue));
    }

    /**
     */
    async dataModelAdd(dataModelDto: DataModelDto): Promise<DataModelDto> {
        const response = await this.dataModelAddRaw({ dataModelDto: dataModelDto });
        return await response.value();
    }

    /**
     */
    async dataModelDeleteRaw(requestParameters: DataModelDeleteRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dataModelDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/datamodel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async dataModelDelete(id: number): Promise<Blob> {
        const response = await this.dataModelDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dataModelGetRaw(requestParameters: DataModelGetRequest): Promise<runtime.ApiResponse<DataModelDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dataModelGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/datamodel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataModelDtoFromJSON(jsonValue));
    }

    /**
     */
    async dataModelGet(id: number): Promise<DataModelDto> {
        const response = await this.dataModelGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dataModelGetAllRaw(): Promise<runtime.ApiResponse<Array<DataModelDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/datamodel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataModelDtoFromJSON));
    }

    /**
     */
    async dataModelGetAll(): Promise<Array<DataModelDto>> {
        const response = await this.dataModelGetAllRaw();
        return await response.value();
    }

    /**
     */
    async dataModelUpdateRaw(requestParameters: DataModelUpdateRequest): Promise<runtime.ApiResponse<DataModelDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dataModelUpdate.');
        }

        if (requestParameters.dataModelDto === null || requestParameters.dataModelDto === undefined) {
            throw new runtime.RequiredError('dataModelDto','Required parameter requestParameters.dataModelDto was null or undefined when calling dataModelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/datamodel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DataModelDtoToJSON(requestParameters.dataModelDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DataModelDtoFromJSON(jsonValue));
    }

    /**
     */
    async dataModelUpdate(id: number, dataModelDto: DataModelDto): Promise<DataModelDto> {
        const response = await this.dataModelUpdateRaw({ id: id, dataModelDto: dataModelDto });
        return await response.value();
    }

}
