/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetTypes,
    WidgetTypesFromJSON,
    WidgetTypesFromJSONTyped,
    WidgetTypesToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {number}
     * @memberof WidgetDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetDto
     */
    dataModel: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WidgetDto
     */
    dataMap: { [key: string]: string; };
    /**
     * 
     * @type {WidgetTypes}
     * @memberof WidgetDto
     */
    type: WidgetTypes;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    config?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDto
     */
    users?: Array<string> | null;
}

export function WidgetDtoFromJSON(json: any): WidgetDto {
    return WidgetDtoFromJSONTyped(json, false);
}

export function WidgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'dataModel': json['dataModel'],
        'dataMap': json['dataMap'],
        'type': WidgetTypesFromJSON(json['type']),
        'config': !exists(json, 'config') ? undefined : json['config'],
        'users': !exists(json, 'users') ? undefined : json['users'],
    };
}

export function WidgetDtoToJSON(value?: WidgetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'dataModel': value.dataModel,
        'dataMap': value.dataMap,
        'type': WidgetTypesToJSON(value.type),
        'config': value.config,
        'users': value.users,
    };
}


