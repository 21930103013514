



















import Vue from 'vue';
import Client from '../../api/Client';
import { WidgetDataDto, WidgetDto } from '../../api/gen';

export default Vue.extend({
  name: 'AnalogueClockWidget',
  props : {
    dto: {
      type: Object as () => WidgetDto,
      required: true,
    },
  },
  data() {
    return {
      timezone: "",
      timer: 0,
      hourRotate: "rotatez(0deg)",
      minuteRotate: "rotatez(0deg)",
      secondRotate: "rotatez(0deg)",
      transform: "scale(1)",
      timeList: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      textColor: "red",
      title: "",
      showTitle: false,
      fontSize: "12px"
    };
  },
  methods: {
    async loadData() {
      let client = new Client();

      this.title = Buffer.from(this.dto.name, "base64").toString();

      let widgetData: WidgetDataDto;
      widgetData = await client.widget.widgetGetData(this.dto.id);

      if(widgetData.data.timezone != null && widgetData.data.timezone.length > 0)
        this.timezone = widgetData.data.timezone;
      else
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.show();
    },
    show() {
        this.showTime();
        if (this.timer) window.clearInterval(this.timer);
            this.timer = window.setInterval(() => {
                this.showTime();
            }, 100);
    },
    showTime() {
        const options: Intl.DateTimeFormatOptions = {
          timeZone: this.timezone,
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        };

        const formatter = new Intl.DateTimeFormat([], options);
        const formattedTime = formatter.format(new Date());

        // Parse the formatted time back into a Date object
        const parts = formattedTime.split(':');

        const times = [parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2], 10)];
    
        let hour = +times[0];
        hour = hour > 11 ? hour - 12 : hour;
        let minute = +times[1];
        let second = +times[2] || 0;
        let hourAngle = hour * 30 + minute * 6 / 360 * 30;
        let minuteAngle = minute * 6;
        let secondAngle = second * 6;
        this.hourRotate = `rotatez(${hourAngle}deg)`;
        this.minuteRotate = `rotatez(${minuteAngle}deg)`;
        this.secondRotate = `rotatez(${secondAngle}deg)`;
    }
  },
  destroyed() {
      if (this.timer) clearInterval(this.timer);
  },
  mounted() {
    const parentContainer = this.$refs.parentContainer as HTMLElement;

    const containerWidth = parentContainer.clientWidth;
    const containerHeight = parentContainer.clientHeight;

    const objectWidth = 160;
    const objectHeight = 170;

    const scaleX = containerWidth / objectWidth;
    const scaleY = containerHeight / objectHeight;

    const scale = Math.min(scaleX, scaleY);

    this.transform = `scale(${scale})`;
    this.fontSize = (12 / (scale/2 + 0.5)) + "px";

    try {
      let config = JSON.parse(this.dto.config ?? "{}");

      this.showTitle = config?.ShowTitle ?? false;

      (this.$refs["clockDial"] as HTMLElement).style.backgroundColor = config?.DialColor ?? "transparent";
      this.textColor = config?.TextColor ?? "white";
    } catch { }
  }
});
