















  import Vue from 'vue';

  export default Vue.extend({
    name: "AnalogueClockEditWidget",
    props: {
      config: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        dialColor: "",
        dialOpacity: 1,
        localConfig: this.config
      }
    },
    mounted() {
      this.dialColor = this.config.DialColor?.substr(0, 7) ?? "#000000";
      this.dialOpacity = Number("0x" + (this.config.DialColor?.substr(7, 2) ?? "ff"));
    },
    methods: {
      updateColor() {
        let opacity = Math.round(Math.min(Math.max(this.dialOpacity || 255, 0), 255));
        this.localConfig.DialColor = this.dialColor + opacity.toString(16).padStart(2, '0');
        this.$emit('updated', this.localConfig);
      },
    }
});
