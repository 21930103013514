










































import { i18n } from '@consolidate/shared/util-translations';
import Vue from 'vue';
import Client from '../api/Client';

var items: { ID: number; Name: string; Users: string }[] = [];

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      fields: [
        {
          key: 'ID',
          label: i18n.t('ID'),
          sortable: true,
        },
        {
          key: 'Name',
          label: i18n.t('DASHBOARD_NAME'),
          sortable: true,
        },
        {
          key: 'Users',
          label: i18n.t('USERS'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: items,
      loaded: false,
      filterText: null,
    };
  },
  async created() {
    this.loaded = false;

    await this.loadData();

    this.loaded = true;
  },
  methods: {
    async loadData() {
      let client = new Client();
      let widgets = await client.widget.widgetGetAll();

      this.items = [];

      for (let widget of widgets) {
        let users: string;
        if (widget.users) {
          users = widget.users.join(', ');
        } else {
          users = '';
        }
        this.items.push({
          ID: widget.id,
          Name: Buffer.from(widget.name, 'base64').toString(),
          Users: users,
        });
      }
    },

    showDeleteModal(id: number) {
      this.$bvModal
        .msgBoxConfirm(i18n.t('DELETE_WIDGET_MSG'), {
          title: i18n.t('DELETE_WIDGET'),
          okVariant: 'danger',
          okTitle: i18n.t('DELETE'),
          cancelTitle: i18n.t('CANCEL'),
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value == true) {
            await this.deleteItem(id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async deleteItem(id: number) {
      this.loaded = false;
      let client = new Client();
      try {
        await client.widget.widgetDelete(id);
        await this.loadData();
      } catch (e) {
        alert(e);
      }

      this.loaded = true;
    },
  },
});
