


























  import Vue from 'vue';

  export default Vue.extend({
    name: "ValueEditWidget",
    props: {
      config: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        lat: 47.42173,
        lng: 9.67230,
        localConfig: this.config
      }
    },
    mounted() {
      if (this.localConfig?.DefaultLocation != null) {
        this.lat = this.localConfig.DefaultLocation[0];
        this.lng = this.localConfig.DefaultLocation[1];
      }
    },
    methods: {
      changed() {
        this.localConfig.DefaultLocation = [this.lat, this.lng];
        this.$emit('updated', this.localConfig);
      }
    }
});
