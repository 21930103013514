

































  import Vue from 'vue';

  export default Vue.extend({
    name: "BaseConfigWidget",
    props: {
      config: {
        type: Object,
        required: true
      },
      type: {
        type: Number,
        required: false
      },
    },
    data() {
      return {
        backgroundColor: "#000000",
        backgroundOpacity: 1,
        localConfig: this.config
      }
    },
    mounted() {
      if(this.config.BackgroundColor == null || this.config.BackgroundColor.length < 9)
        this.localConfig.BackgroundColor = "#000000ff";

      if (this.config.TextColor == null || this.config.TextColor.length < 7)
        this.localConfig.TextColor = "#ffffff";

      this.backgroundColor = this.localConfig.BackgroundColor.substr(0, 7);
      this.backgroundOpacity = Number("0x" + this.localConfig.BackgroundColor.substr(7, 2));
    },
    methods: {
      updateColor() {
        let opacity = Math.round(Math.min(Math.max(this.backgroundOpacity || 255, 0), 255));
        this.localConfig.BackgroundColor = this.backgroundColor + opacity.toString(16);
        this.$emit('updated', this.localConfig);
      },
    }
});
