/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingsDto
 */
export interface SettingsDto {
    /**
     * 
     * @type {string}
     * @memberof SettingsDto
     */
    googleMapsApiKey: string;
}

export function SettingsDtoFromJSON(json: any): SettingsDto {
    return SettingsDtoFromJSONTyped(json, false);
}

export function SettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googleMapsApiKey': json['googleMapsApiKey'],
    };
}

export function SettingsDtoToJSON(value?: SettingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'googleMapsApiKey': value.googleMapsApiKey,
    };
}


