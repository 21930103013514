






























































  import Vue from 'vue';
  import Client from '../api/Client';
  import { WidgetDto, WidgetTypes, WidgetTypeDto } from '../api/gen';
  import { i18n } from '@consolidate/shared/util-translations';
  import BaseConfigWidget from '../components/edit-widgets/BaseConfigWidget.vue';
  import AnalogueClockEditWidget from '../components/edit-widgets/AnalogueClockEditWidget.vue';
  import LineChartEditWidget from '../components/edit-widgets/LineChartEditWidget.vue';
  import TableEditWidget from '../components/edit-widgets/TableEditWidget.vue';
  import ValueEditWidget from '../components/edit-widgets/ValueEditWidget.vue';
  import MapEditWidget from '../components/edit-widgets/MapEditWidget.vue';

  var datamodels: {value: number, text: string, data: string[]}[] = [];
  var selectedDataModel: number;
  var selectedWidgetType: number;
  var widgetTypes: {value: number, text: string}[] = [
    {value: 5, text: i18n.t("IMAGE")},
    {value: 10, text: i18n.t("MAP")},
    {value: 12, text: i18n.t("CHART_PIE")},
    {value: 11, text: i18n.t("CHART_LINE")},
    {value: 7, text: i18n.t("TABLE")},
    {value: 1, text: i18n.t("CLOCK_ANALOGUE")},
    {value: 2, text: i18n.t("CLOCK_DIGITAL")},
    {value: 9, text: i18n.t("VALUE")},
  ];

  var widgetTypeInfo: WidgetTypeDto[] = [];
  var datamap: { datamodel: string, widget: string }[] = [];
  var datamapoptions: string[] = [];
  var config: any = {};

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      config: config,
      name: "",
      users: "",
      id: 0,
      loaded: false,
      datamodels: datamodels,
      selectedDataModel: selectedDataModel,
      selectedWidgetType: selectedWidgetType,
      widgetTypes: widgetTypes,
      datamap: datamap,
      datamapoptions: datamapoptions,
      datamapfields: [
        { key: 'widget', label: i18n.t("WIDGET"), sortable: false },
        {key: 'datamodel', label: i18n.t("DATAMODEL"), sortable: false}
      ]
    }
  },
  components: {
    BaseConfigWidget,
    AnalogueClockEditWidget,
    LineChartEditWidget,
    TableEditWidget,
    ValueEditWidget,
    MapEditWidget
  },
  async created() {
    let id: number = parseInt(this.$route.params.id);
    let client = new Client();

    this.datamap = [];
    this.datamodels = [];
    let models = await client.dataModel.dataModelGetAll();
    for (let model of models) {
      this.datamodels.push({ value: model.id, text: Buffer.from(model.name, "base64").toString(), data: model.data });
    }

    this.datamodels = this.datamodels.sort((a,b) => {
			let fa = a.text.toLowerCase(), fb = b.text.toLowerCase();
			if (fa < fb) {
				return -1
			}
			if (fa > fb) {
				return 1
			}
			return 0
		})

    widgetTypeInfo = await client.widget.widgetGetWidgetTypes();

    if (id > 0) {
      let dto: WidgetDto = await client.widget.widgetGet(id);
      this.name = Buffer.from(dto.name, "base64").toString();
      this.users = dto.users?.join(",") ?? "";
      this.selectedDataModel = dto.dataModel;
      this.id = dto.id;
      this.selectedWidgetType = dto.type;

      for (let key in dto.dataMap) {
        this.datamap.push({ datamodel: dto.dataMap[key], widget: key });
      }

      this.dataModelChanged(this.selectedDataModel);
      this.typeChanged(true);
      this.config = JSON.parse(dto.config ?? "{}");
    }
    else {
      this.id = 0;
      this.name = i18n.t("NEW_WIDGET");
      this.users = "";
    }

    this.loaded = true;
  },
  methods: {
    async save() {
      let client = new Client();

      let strUsers: string[] = this.users.split(",").map(x => x.trim()).filter(function (el) { return el != null && el.length > 0 });
      let dataMapResult: { [key: string]: string } = {};
      for (let d of this.datamap) {
        if (d.datamodel != null && d.datamodel.length > 0 && d.widget != null && d.widget.length > 0)
          dataMapResult[d.widget] = d.datamodel;
      }

      let reqDto: WidgetDto = {
        id: this.id,
        name: Buffer.from(this.name).toString("base64"),
        users: strUsers,
        type: this.selectedWidgetType as WidgetTypes,
        dataModel: this.selectedDataModel,
        dataMap: dataMapResult,
        config: JSON.stringify(this.config)
      };

      try {
        if (reqDto.id > 0) {
          await client.widget.widgetUpdate(reqDto.id, reqDto);
        }
        else {
          await client.widget.widgetAdd(reqDto);
        }

        // this.$router.push('/widgets'); 
        this.$bvModal
          .msgBoxOk(i18n.t('SAVE_SUCCESS'), {
            title: i18n.t('INFO'),
            okVariant: 'success',
            okTitle: i18n.t('OK'),
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            //
          })
          .catch((err) => {
            // An error occurred
          });
      }
      catch (e) {
        alert(e);
      }
    },
    typeChanged(init: boolean) {
      if (!init)
        this.datamap = [];

      if (this.selectedWidgetType != null) {
        let type = widgetTypeInfo.find(v => v.type === this.selectedWidgetType);
        if (type != undefined && type != null) {
          for (let d of type.data) {
            if (this.datamap.find(v => v.widget == d) == undefined)
              this.datamap.push({ datamodel: "", widget: d });
          }
        }
      }
    },
    dataModelChanged(newModel: number) {
      this.selectedDataModel = newModel;
      this.datamapoptions = [];
      if (this.selectedDataModel != null) {
        let model = this.datamodels.find(v => v.value === this.selectedDataModel);
        if(model != undefined && model != null)
          this.datamapoptions = model.data;
      }
    },
    baseConfigChanged(newConfig: any) {
      this.config.BackgroundColor = newConfig.BackgroundColor;
      this.config.TextColor = newConfig.TextColor;
      this.config.RefreshRate = newConfig.RefreshRate;
      this.config.ShowTitle = newConfig.ShowTitle;
    },
    analogueClockConfigChanged(newConfig: any) {
      this.config.DialColor = newConfig.DialColor;
    },
    lineChartConfigChanged(newConfig: any) {
      this.config.LineColor = newConfig.LineColor;
      this.config.AreaChart = newConfig.AreaChart;
    },
    mapConfigChanged(newConfig: any) {
      this.config.ZoomLevel = newConfig.ZoomLevel;
      this.config.DefaultLocation = newConfig.DefaultLocation;
      this.config.FitBounds = newConfig.FitBounds;
    },
    tableConfigChanged(newConfig: any) {
      this.config.Dark = newConfig.Dark;
      this.config.EnableSearch = newConfig.EnableSearch;
      this.config.showColumnHeader = newConfig.showColumnHeader;
    },
    valueConfigChanged(newConfig: any) {
      this.config.FontSize = newConfig.FontSize;
    }
  }
});
