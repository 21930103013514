/* tslint:disable */
/* eslint-disable */
/**
 * Consolidate Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DashboardDto,
    DashboardDtoFromJSON,
    DashboardDtoToJSON,
} from '../models';

export interface DashboardAddRequest {
    dashboardDto: DashboardDto;
}

export interface DashboardDeleteRequest {
    id: number;
}

export interface DashboardGetRequest {
    id: number;
}

export interface DashboardUpdateRequest {
    id: number;
    dashboardDto: DashboardDto;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     */
    async dashboardAddRaw(requestParameters: DashboardAddRequest): Promise<runtime.ApiResponse<DashboardDto>> {
        if (requestParameters.dashboardDto === null || requestParameters.dashboardDto === undefined) {
            throw new runtime.RequiredError('dashboardDto','Required parameter requestParameters.dashboardDto was null or undefined when calling dashboardAdd.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDtoToJSON(requestParameters.dashboardDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardAdd(dashboardDto: DashboardDto): Promise<DashboardDto> {
        const response = await this.dashboardAddRaw({ dashboardDto: dashboardDto });
        return await response.value();
    }

    /**
     */
    async dashboardDeleteRaw(requestParameters: DashboardDeleteRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async dashboardDelete(id: number): Promise<Blob> {
        const response = await this.dashboardDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dashboardGetRaw(requestParameters: DashboardGetRequest): Promise<runtime.ApiResponse<DashboardDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardGet(id: number): Promise<DashboardDto> {
        const response = await this.dashboardGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dashboardGetAllRaw(): Promise<runtime.ApiResponse<Array<DashboardDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardDtoFromJSON));
    }

    /**
     */
    async dashboardGetAll(): Promise<Array<DashboardDto>> {
        const response = await this.dashboardGetAllRaw();
        return await response.value();
    }

    /**
     */
    async dashboardUpdateRaw(requestParameters: DashboardUpdateRequest): Promise<runtime.ApiResponse<DashboardDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dashboardUpdate.');
        }

        if (requestParameters.dashboardDto === null || requestParameters.dashboardDto === undefined) {
            throw new runtime.RequiredError('dashboardDto','Required parameter requestParameters.dashboardDto was null or undefined when calling dashboardUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/v2/dashboard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDtoToJSON(requestParameters.dashboardDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardUpdate(id: number, dashboardDto: DashboardDto): Promise<DashboardDto> {
        const response = await this.dashboardUpdateRaw({ id: id, dashboardDto: dashboardDto });
        return await response.value();
    }

}
