









  import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      checked: true,
    };
  },
 mounted() {
  // get the localStorage theme value if it exists set the theme to the value
  const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;
  currentTheme && document.documentElement.setAttribute('data-theme', currentTheme);

  // pre-check the dark-theme checkbox if dark-theme is set
   if (document.documentElement.getAttribute("data-theme") == "light") {
     this.checked = false;
     (this.$root as any).darkMode = false;
     (this.$root as any).themeName = "light";
   }
   else {
     this.checked = true;
     (this.$root as any).darkMode = true;
     (this.$root as any).themeName = "dark";
   }
 },

 methods: {
   switchTheme(e: Event) {
     if ((e.target as HTMLInputElement).checked) {
            localStorage.setItem('theme', 'dark');
            document.documentElement.setAttribute('data-theme', 'dark');
          this.checked = true;
          (this.$root as any).darkMode = true;
          (this.$root as any).themeName = "dark";
        } else {
            localStorage.setItem('theme', 'light');
            document.documentElement.setAttribute('data-theme', 'light');
          this.checked = false;
          (this.$root as any).darkMode = false;
          (this.$root as any).themeName = "light";
        }
    },
  },
});
